<template>
    <b-container >
        <b-col md="6" offset-md="3">
            <b-modal id="updateModelQty" hide-footer size="lg" title="Update Company" >
                <form @submit.prevent="updateCompany" @reset.prevent="closeModal">
                    <div class="row">
                        <div class="col-md-12" v-if="company_quantity">
                            <div class="row">
                                <div class="col-md-12 ">
                                    <div class="sections-three">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Allowed Projects</label>
                                                    <b-form-input type="number" v-model="data.total_allowed_projects"
                                                                  placeholder="Enter Allowed Projects"></b-form-input>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Allowed Members</label>
                                                    <b-form-input type="number" v-model="data.total_allowed_members"
                                                                  placeholder="Enter Allowed Members"></b-form-input>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Allowed Tasks</label>
                                                    <b-form-input type="number" v-model="data.total_allowed_tasks"
                                                                  placeholder="Enter Allowed Tasks"></b-form-input>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div slot="modal-footer" class="col-md-12 text-center">
                            <button type="submit" class="btn btn-primary m-2">Update</button>
                            <button type="reset" class="btn btn-secondary m-2">Cancel</button>
                        </div>
                    </div>
                </form>
            </b-modal>
        </b-col>
    </b-container>

</template>

<script>
    export default {
        name: "CompanyUpdate",
        data() {
            return {
                data:{
                    total_allowed_projects:'',
                    total_allowed_members:'',
                    total_allowed_tasks:''
                },
                company_quantity: null,
            };
        },
        mounted() {
            this.$root.$on("selected_company_quantity", company_quantity => {
                this.company_quantity = company_quantity;
                this.data.total_allowed_projects = company_quantity.total_allowed_projects
                this.data.total_allowed_members = company_quantity.total_allowed_members,
                    this.data.total_allowed_tasks = company_quantity.total_allowed_tasks
            });
        },

        methods: {
            updateCompany() {
                let data = {
                    ...this.data,
                    company_id: this.company_quantity.hash_id
                };
                this.$store.commit("toggle_loader", true);
                this.$store
                    .dispatch("updateCompanyQty", data)
                    .then(response => {
                        this.$store.commit("toggle_loader", false);
                        this.$emit('updateCompany', true)
                        this.$root.$emit("bv::hide::modal", "updateModelQty");
                        this.$notify("Success!", response.data.error_msg, "success");
                    })
                    .catch(error => {
                        this.$store.commit("toggle_loader", false);
                        if (error.response.status === 422 && error.response.data.message)
                            this.$notify("Error!", Object.values(error.response.data.errors)[0][0], "danger");

                    });
            },
            closeModal() {
                this.$root.$emit("bv::hide::modal", "updateModelQty");
            },

        }
    };
</script>

<style scoped>
    .alignment {
        left: 5%;

    }

    ul {
        list-style: none
    }
    input {
        margin-right: .5em;
    }

    .label-setting{
        width: 172px;
    }
    .sections-five{
        margin-bottom: 30px;
    }

    .heading-style{
        font-size: 15px !important;
    }
</style>
